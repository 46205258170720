@import "primeicons/primeicons.css";
@import "../node_modules/primeflex/primeflex.scss";
@import './styles/_layout/layout.scss';
@import './styles/_ui/';
@import './styles/_ckeditor/';

:root {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #000000;
  -webkit-text-size-adjust: none;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
  margin: 0;
}

p,
ul {
  margin: 0;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

a:hover {
  color: #1768bf;
}

.container {
  max-width: 1280px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    max-width: 1180px;
  }

  @media (max-width: 1280px) {
    max-width: 991px;
  }

  @media (max-width: 1000px) {
    max-width: 755px;
  }

  @media (max-width: 767px) {
    max-width: none;
    padding: 0 16px;
  }
}
