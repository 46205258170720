%h1 {
  font-size: 38px;

  @media (max-width: 1200px) {
    font-size: 34px;
  }

  @media (max-width: 991px) {
    font-size: 30px;
  }

  @media (max-width: 767px) {
    font-size: 28px;
  }
}

%h2 {
  font-size: 30px;

  @media (max-width: 1200px) {
    font-size: 32px;
  }

  @media (max-width: 991px) {
    font-size: 26px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
}

%h3 {
  font-size: 20px;

  @media (max-width: 767px) {
    font-size: 18px;
  }
}

%h4 {
  font-size: 18px;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

%h5 {
  font-size: 18px;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

%h6 {
  font-size: 16px;
}

%p {
  font-size: 16px;
  line-height: 24px;

  @media (min-width: 1199px) {
    line-height: 26px;
  }

  @media (min-width: 1439px) {
    font-size: 18px;
    line-height: 30px;
  }
}
